import { Avatar, Box, CircularProgress, Modal } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import useQuery from "../../../../Utils/querry";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Close } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  borderRadius: "10px",
  bgcolor: "background.paper",
  boxShadow: 24,

  p: 4,
};
export default function Form({ setValue }) {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [type, settype] = useState("Startup");
  const [status, setstatus] = useState("New Lead");
  const [expectedRevenue, setexpectedRevenue] = useState(null);
  const [isAssociation, setisAssociation] = useState(false);
  const [IsHorvathLead, setIsHorvathLead] = useState(false);
  const [Priority, setPriority] = useState("High");
  const [incubationTool, setincubationTool] = useState(false);
  const [RevenueHorvath, setRevenueHorvath] = useState(false);
  const [notes, setnotes] = useState("");
  const [logo, setLogo] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const query = useQuery();
  const saleid = query.get("id");
  const navigate = useNavigate();
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [engagementdate, setengagementdate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [contactName, setcontactName] = useState("");
  const [alternativeContact, setAlternativeContact] = useState("");
  const [LinkedIn, setLinkedIn] = useState("");
  const [associationPosition, setAssociationPosition] = useState("");
  const [responsibility, setResponsibility] = useState("None");
  const [invoiceAmount, setinvoiceAmount] = useState("");
  const [offerType, setofferType] = useState("Platform");
  const [internalRes, setinternalRes] = useState("None");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getDetails = async (id) => {
    await axios({
      url: `/api/sale/crm/${id}`,
      method: "get",
    })
      .then((res) => {
        const { data } = res.data;
        setname(data.name);
        setemail(data.email);
        settype(data.userType);
        setstatus(data.status);
        setexpectedRevenue(data.expectedRevenue);
        setisAssociation(data.isAssociation);
        setIsHorvathLead(data.isHorvathLead);
        setPriority(data.Priority);
        setincubationTool(data.isInterestedInIncubationTool);
        setnotes(data.note);
        setRevenueHorvath(data.isRevenueShareWithHorvath);
        setLogo(data.logo);
        setDate(moment(data.date).format("YYYY-MM-DD"));
        setengagementdate(moment(data.engagementdate).format("YYYY-MM-DD"));
        setcontactName(data.contactName);
        setAlternativeContact(data.alternativeContact);
        setAssociationPosition(data.associationPosition);
        setLinkedIn(data.LinkedIn);
        setResponsibility(data.responsibility);
        setinvoiceAmount(data.invoiceAmount);
        setofferType(data.offerType);
        setinternalRes(data.internalRes);
        setselectedUser(data.userId);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
        setValue(1);
      });
  };

  useEffect(() => {
    if (saleid) {
      getDetails(saleid);
    }
  }, [saleid]);

  const handleUploadLogo = (e) => {
    const image = e.target.files[0];
    if (image.size > 2048 * 1024) {
      toast.error("File size exceeded 2MB!", { position: "bottom-right" });
      return;
    }
    const formdata = new FormData();
    formdata.append("crm-logo", image);
    setIsLoading(true);
    axios({
      method: "post",
      url: " /api/upload/crmlogo",
      data: formdata,
    })
      .then((res) => {
        setIsLoading(false);
        const { location } = res.data;
        setLogo(location);
        toast.success("Image upload", { position: "bottom-right" });
      })
      .catch(() => {
        setIsLoading(false);
        toast.error("Error in upload", { position: "bottom-right" });
      });
  };

  const handleSubmit = async () => {
    try {
      var response = await axios.post("/api/sale/add", {
        logo,
        name,
        email,
        userType: type,
        status,
        expectedRevenue: expectedRevenue,
        isAssociation: isAssociation,
        isHorvathLead: IsHorvathLead,
        isRevenueShareWithHorvath: RevenueHorvath,
        isInterestedInIncubationTool: incubationTool,
        Priority: Priority,
        note: notes,
        date,
        engagementdate,
        id: saleid ? saleid : null,
        contactName: contactName,
        LinkedIn,
        alternativeContact,
        associationPosition,
        invoiceAmount,
        offerType,
        responsibility,
        internalRes,
        userId: selectedUser ? selectedUser._id : null,
      });
      if (response.data.success) {
        if (!saleid || saleid === "") {
          setname("");
          setemail("");
          settype("");
          setstatus("New Lead");
          setexpectedRevenue("");
          setisAssociation(false);
          setIsHorvathLead(false);
          setPriority("High");
          setincubationTool(false);
          setRevenueHorvath(false);
          setnotes("");
          setcontactName("");
          setLogo("");
          setDate(moment().format("YYYY-MM-DD"));
          setengagementdate(moment().format("YYYY-MM-DD"));
          setLinkedIn("");
          setAssociationPosition("");
          setAlternativeContact("");
          setinvoiceAmount("");
          setofferType("");
          setResponsibility("None");
          setinternalRes("None");
        }
        toast.success(response.data.message);
        // if (saleid) {
        //   navigate(`/admin/sales`);
        //   setValue(1);
        // }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleDelete = async () => {
    try {
      let res = await axios.get(`/api/sale/delete/${saleid}`);
      if (res.data.success) {
        if (saleid) {
          toast.success("Contact Deleted");
          handleClose();
          navigate(`/admin/sales`);
          setValue(1);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const [searchuser, setsearchuser] = useState("");
  const [fetchedusers, setfetchedusers] = useState([]);
  const [selectedUser, setselectedUser] = useState(null);

  const fetchUserByName = async () => {
    try {
      const response = await axios.get(`/api/admin/searchuser?s=${searchuser}`);
      if (response.data.success) {
        setfetchedusers(response.data.data);
      }
    } catch (error) {
      if (error && error.response && error.response.data) {
        toast.error(error.response.data.error);
      }
    }
  };

  useEffect(() => {
    if (searchuser?.trim() != "") fetchUserByName();
    else setfetchedusers([]);
  }, [searchuser]);

  return (
    <div className="container ">
      <h1 className="mb-5">Form to add leads</h1>
      <div>
        <div className="row mt-2 align_center">
          <div className="col-md-2">
            {isLoading ? (
              <CircularProgress />
            ) : logo ? (
              <div className="text-center">
                <img
                  src={logo}
                  alt="crm"
                  className="mx-auto"
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "50%",
                    border: "1px solid #fff",
                    objectFit: "cover",
                    marginBottom: 2,
                  }}
                />
                <p
                  onClick={() => setLogo("")}
                  className="pointer red text-center"
                >
                  Delete
                </p>
              </div>
            ) : (
              <>
                <label className="w-100 white">Logo</label>
                <input
                  onChange={(e) => handleUploadLogo(e)}
                  type="file"
                  className="w-100 mb-3 input-tag"
                />
              </>
            )}
          </div>
          <div className="col-md-5 col-lg-5 col-sm-12">
            <label className="w-100 white" htmlFor="name">
              Company Name
            </label>
            <input
              value={name}
              onChange={(e) => setname(e.target.value)}
              type="text"
              className="w-100 mb-3 input-tag"
              name="name"
            />
          </div>
          <div className="col-md-5 col-lg-5 col-sm-12">
            <label className="w-100 white" htmlFor="contact_name">
              {" "}
              Contact Name
            </label>
            <input
              value={contactName}
              onChange={(e) => setcontactName(e.target.value)}
              type="text"
              className="w-100 mb-3 input-tag"
              name="contact_name"
            />
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-md-4 col-lg-4 col-sm-12">
            <label className="w-100 white" htmlFor="email">
              Email
            </label>
            <textarea
              value={email}
              onChange={(e) => setemail(e.target.value)}
              type="email"
              className="w-100 mb-3 input-tag"
              name="email"
            />
          </div>

          <div className="col-md-4 col-lg-4 col-sm-12">
            <label className="w-100 white">Contact Number</label>
            <textarea
              value={alternativeContact}
              onChange={(e) => setAlternativeContact(e.target.value)}
              type="text"
              className="w-100 mb-3 input-tag"
            />
          </div>
          <div className="col-md-4 col-lg-4 col-sm-12">
            <label className="w-100 white">LinkedIn</label>
            <textarea
              value={LinkedIn}
              onChange={(e) => setLinkedIn(e.target.value)}
              type="text"
              className="w-100 mb-3 input-tag"
            />
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-lg-3 col-md-4 col-sm-12">
            <label className="w-100 white" htmlFor="date">
              Date
            </label>
            <input
              value={date}
              onChange={(e) => setDate(e.target.value)}
              type="date"
              className="w-100 mb-3 input-tag"
              name="date"
            />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12">
            <label className="w-100 white" htmlFor="date">
              Next Engagement Date
            </label>
            <input
              value={engagementdate}
              onChange={(e) => setengagementdate(e.target.value)}
              type="date"
              className="w-100 mb-3 input-tag"
              name="date"
            />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12">
            <label className="w-100 white mb-1">Type of user</label>
            <select
              value={type}
              onChange={(e) => settype(e.target.value)}
              type="text"
              className="w-100 mb-3 input-tag"
            >
              <option value={"Startup"}>Startup</option>
              <option value={"Investor"}>Investor</option>
              <option value={"Solo Investor"}>Solo Investor</option>
              <option value={"Corporate"}>Corporate</option>
              <option value={"Academia"}>Academia</option>
            </select>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12">
            <label className="w-100 white mb-1">Status</label>
            <select
              onChange={(e) => setstatus(e.target.value)}
              value={status}
              className="mb-3 w-100 input-tag"
            >
              <option value={""}>Select Status</option>
              <option value={"New Lead"}>New Lead</option> {/* Contacted  */}
              <option value={"1st Contact"}>1st Contact</option>
              <option value={"Initial Meet"}>Initial Meet</option>{" "}
              {/* Demo Conducted */}
              <option value={"Test Account"}>Test Account</option>
              <option value={"Offer Placed"}>Offer Placed</option>
              <option value={"Offer Accepted"}>Offer Accepted </option>
              <option value={"Invoice Sent"}>Invoice Sent</option>
              <option value={"Money Received"}>Money Received</option>
              <option value={"Customer Nurturing"}>Customer Nurturing</option>
              {/* <option value={"2nd Invoice Sent"}>2nd Invoice Sent</option>
              <option value={"2nd Money Received"}>2nd Money Received</option> */}
              <option value={"Declined"}>Declined</option>
              <option value={"Lost"}>Lost</option>
              <option value={"No Potential"}>No Potential</option>
            </select>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <label className="w-100 white ">Invoice Amount (EUR)</label>
            <input
              value={invoiceAmount}
              onChange={(e) => setinvoiceAmount(e.target.value)}
              type="number"
              placeholder="200,00"
              className="w-100 mb-2 input-tag"
            />
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <label className="w-100 white ">Expected Revenue </label>
            <input
              value={expectedRevenue}
              onChange={(e) => setexpectedRevenue(e.target.value)}
              type="text"
              className="w-100 mb-2 input-tag"
            />
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12">
            <label className="w-100 white mb-1">Assign Responsibility</label>
            <select
              onChange={(e) => setResponsibility(e.target.value)}
              value={responsibility}
              className="mb-3 w-100 input-tag"
            >
              <option value={"Susanne Hahn"}>Susanne Hahn</option>
              <option value={"Aparna Veer"}>Aparna Veer</option>
              <option value={"Daniel"}>Daniel Bode</option>
              <option value={"Simon"}>Simon Zieker</option>
              <option value={"Volker"}>Volker Gesman</option>
              <option value={"Fostec Ventures"}>Fostec Ventures</option>
              <option value={"Nikolai Ivansson"}>Nikolai Ivansson</option>
              <option value={"Marc-Oliver Nandy"}>Marc-Oliver Nandy</option>
              <option value={"Kurt Knoesslsdorfer"}>Kurt Knoesslsdorfer</option>
              <option value={"Rahul Sawlani"}>Rahul Sawlani</option>
              <option value={"None"}>None</option>
            </select>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <label className="w-100 white">Priority</label>
            <select
              onChange={(e) => setPriority(e.target.value)}
              value={Priority}
              className="mb-3 w-100 input-tag"
            >
              <option value="High">High</option>
              <option value="Medium">Medium</option>
              <option value="Low">Low</option>
            </select>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <label className="w-100 white">Offer Type</label>
            <select
              onChange={(e) => setofferType(e.target.value)}
              value={offerType}
              className="mb-3 w-100 input-tag"
            >
              <option value="Platform">Platform</option>
              <option value="Services">Services</option>
              <option value="">Both</option>
            </select>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <label className="w-100 white mb-1">
              Are they interested in Incubation Tool?
            </label>
            <select
              onChange={(e) => setincubationTool(e.target.value)}
              value={incubationTool}
              className="mb-3 w-100 input-tag"
            >
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </select>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <label className="w-100 white mb-1">Internal Responsibility</label>
            <select
              onChange={(e) => setinternalRes(e.target.value)}
              value={internalRes}
              className="mb-3 w-100 input-tag"
            >
              <option value={"Susanne Hahn"}>Susanne Hahn</option>
              <option value={"Aparna Veer"}>Aparna Veer</option>
              <option value={"Daniel"}>Daniel Bode</option>
              <option value={"Simon"}>Simon Zieker</option>
              <option value={"Volker"}>Volker Gesman</option>
              <option value={"Fostec Ventures"}>Fostec Ventures</option>
              <option value={"Nikolai Ivansson"}>Nikolai Ivansson</option>
              <option value={"Marc-Oliver Nandy"}>Marc-Oliver Nandy</option>
              <option value={"Kurt Knoesslsdorfer"}>Kurt Knoesslsdorfer</option>
              <option value={"Rahul Sawlani"}>Rahul Sawlani</option>
              <option value={"Nayana Yalawatti"}>Nayana Yalawatti</option>
              <option value={""}>None</option>
            </select>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <label className="w-100 white mb-1">Is this Association?</label>
            <select
              onChange={(e) => setisAssociation(e.target.value)}
              value={isAssociation}
              className="mb-3 w-100 input-tag"
            >
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </select>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <label className="w-100 white">Association Position</label>
            <input
              value={associationPosition}
              onChange={(e) => setAssociationPosition(e.target.value)}
              type="text"
              className="w-100 mb-3 input-tag"
            />
          </div>
        </div>
        <div>
          <label className="w-100 white">Notes</label>
          <textarea
            value={notes}
            onChange={(e) => setnotes(e.target.value)}
            type="text"
            className="w-100 mb-3 input-tag h-100px"
          />
        </div>

        <div className="row mt-2 align_center">
          <div className="col-md-3 col-lg-3 col-sm-12 relative">
            <label className="w-100 white" htmlFor="name">
              Search Connected User
            </label>
            <input
              value={searchuser}
              className="w-100 mb-3 input-tag"
              onChange={(e) => setsearchuser(e.target.value)}
              type="text"
              placeholder="Search by firstname/company/email"
            />
            {searchuser.length > 0 &&
              fetchedusers &&
              fetchedusers.length > 0 && (
                <div className="gd_explore_main_results_block sales_form_users">
                  <div style={{ backgroundColor: "white" }}>
                    {fetchedusers.map((v, i) => (
                      <div
                        key={i}
                        onClick={() => {
                          setselectedUser(v);
                          setfetchedusers([]);
                        }}
                        className="gd_explore_main_result d-flex justify-content-between align-items-center mb-16 pointer"
                      >
                        <div className="d-flex align-items-center px-2 py-1">
                          <Avatar
                            src={v.avatar}
                            alt={v.name ?? v.firstname}
                            style={{ height: 40, width: 40 }}
                          />
                          <div style={{ color: "black" }} className="ms-3">
                            <p className="body1 mb-0">
                              {v.type === "member"
                                ? `${v.firstname} ${v.lastname}, (${v.type}) `
                                : `${v.name}, (${v.firstname} ${v.lastname}, ${v.type})`}

                              <span className="caption"> {v.email}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
          </div>
          <div className="col-md-3 col-lg-3 col-sm-12">
            <label className="w-100 white" htmlFor="name">
              Connected Company
            </label>
            <input
              value={selectedUser ? selectedUser?.name : ""}
              type="text"
              className="w-100 mb-3 input-tag"
              name="email"
            />
          </div>
          <div className="col-md-3 col-lg-3 col-sm-12">
            <label className="w-100 white" htmlFor="name">
              Connected Email
            </label>
            <input
              value={selectedUser ? selectedUser.email : ""}
              type="text"
              className="w-100 mb-3 input-tag"
              name="email"
            />
          </div>
          <div className="col-md-2 col-lg-2 col-sm-12">
            <label className="w-100 white" htmlFor="contact_name">
              {" "}
              Connected Contact
            </label>
            <input
              value={
                selectedUser
                  ? selectedUser?.firstname + " " + selectedUser?.lastname
                  : ""
              }
              // onChange={(e) => setcontactName(e.target.value)}
              type="text"
              className="w-100 mb-3 input-tag"
              name="contact_name"
            />
          </div>
          {selectedUser && (
            <span
              className="col-md-1 text-right pointer"
              onClick={() => setselectedUser(null)}
            >
              <Close />
            </span>
          )}
        </div>

        <div className="d-flex justify-content-end mt-16">
          <Button
            className="gradient_admin_btn font20"
            style={{ width: "20%" }}
            onClick={handleSubmit}
          >
            {saleid ? "Update" : "Submit"}
          </Button>
          {saleid && (
            <Button
              className="red_btn ms-4 font20"
              style={{ width: "20%" }}
              onClick={handleOpen}
            >
              Delete
            </Button>
          )}
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h3>Confirm delete this contact?</h3>
          <div className="d-flex mt-5">
            <Button className="red_btn font20" onClick={() => handleDelete()}>
              Yes, Delete
            </Button>
            <Button className="primary_CTA03 ms-3 font20" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
