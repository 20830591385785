import {
  Box,
  CircularProgress,
  IconButton,
  Modal,
  Avatar,
} from "@mui/material";
import Axios from "axios";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import { Add, DriveFileRenameOutline } from "@mui/icons-material";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  borderRadius: "10px",
  bgcolor: "background.paper",
  boxShadow: 24,
  overflowX: "auto",
  overflowY: "hidden",
  p: 4,
};
export default function Invoices() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setisEdit({ isEdit: false, editId: null });
  };

  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => {
    setOpen2(false);
    setuploadedInvoices([]);
  };
  const [customer, setcustomer] = React.useState("");
  const [amount, setamount] = React.useState("");
  const [status, setstatus] = React.useState("paid");
  const [type, setType] = React.useState("");
  const [date, setdate] = React.useState(new Date());
  const [invoiceUrl, setinvoiceUrl] = React.useState("");
  const [isUploading, setIsUploading] = React.useState(false);
  const [person, setperson] = useState("");
  const [fileName, setfileName] = useState("");
  const [email, setemail] = useState("");
  const [crmid, setcrmid] = useState();
  const [userid, setuserid] = useState();
  const [allInvoices, setallInvoices] = useState([]);
  const [uploadedInvoices, setuploadedInvoices] = useState([]);
  const [isEdit, setisEdit] = useState({ isEdit: false, editId: null });
  const [totalRevenue, settotalRevenue] = useState(0);
  const [period, setperiod] = useState("all");
  const [startDate, setstartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setendDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const previousMonths = Array.from({ length: new Date().getMonth() }, (_, i) =>
    new Date(0, i).toLocaleString("default", { month: "long" })
  );
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const handleDocumentsChange = async (e) => {
    e.preventDefault();
    // const file = e.target.files[0];
    // // if (file.type !== "application/pdf") {
    // //   toast.error("PDF files are allowed!");
    // //   return;
    // // }
    // // if (file.size > 2 * 10024 * 1024) {
    // //   // Toast({ msg: "File size exceeded 2MB!", code: 0 });
    // //   toast.error("File size exceeded 20MB!");
    // //   return;
    // // }

    // const formdata = new FormData();
    // formdata.append("pdf", file);
    const files = e.target.files;
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append("pdf", files[i]); // Use an array format (e.g., "pdfs[]") for multiple files
    }

    setIsUploading(true);
    await Axios({
      method: "post",
      url: `https://coral-app-s2kbc.ondigitalocean.app/api/admin/upload/invoice`,
      data: formData,
    })
      .then((res) => {
        if (res.data.success) {
          if (res.data.multipleInvoices) {
            if (open) {
              setcustomer(res.data.multipleInvoices[0]?.customer);
              setamount(res.data.multipleInvoices[0]?.amount);
              setperson(res.data.multipleInvoices[0]?.person);
              setdate(
                moment(res.data.multipleInvoices[0]?.date).format("YYYY-MM-DD")
              );
              setinvoiceUrl(res.data.multipleInvoices[0]?.invoice);
              setfileName(res.data.multipleInvoices[0]?.fileName);
            } else setuploadedInvoices(res.data.multipleInvoices);
          }
          toast.success("uploaded");
          setIsUploading(false);
        }
      })
      .catch(() => {
        setIsUploading(false);
      });
  };
  const getInvoices = async () => {
    try {
      let res = await Axios.get(
        `https://coral-app-s2kbc.ondigitalocean.app/api/admin/invoices?period=${period}&startDate=${startDate}&endDate=${endDate}`
      );
      if (res.data.success) {
        setallInvoices(res.data.invoices);
        let s_rev = 0;
        res.data.invoices.map((rev) => (s_rev = rev.amount + s_rev));
        settotalRevenue(s_rev);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const saveInvoice = async () => {
    if (
      open &&
      (invoiceUrl === "" || date === "" || amount === "" || customer === "")
    ) {
      return toast.error("Incomplete Data");
    }

    try {
      let res = await Axios.post(
        `https://coral-app-s2kbc.ondigitalocean.app/api/admin/submit/multipleinvoices`,
        {
          invoice: invoiceUrl,
          date: date,
          customer: customer,
          amount: amount,
          status: status,
          fileName: fileName,
          person: person,
          email: email,
          isEdit: isEdit,
          crmid,
          userId: userid,
          type: type,
          multipleInvoices: open2
            ? uploadedInvoices
            : [
                {
                  invoice: invoiceUrl,
                  customer,
                  amount,
                  status,
                  fileName,
                  person,
                  email,
                  date,
                },
              ],
        }
      );
      if (res.data.success) {
        handleClose();
        setinvoiceUrl("");
        setdate("");
        setcustomer("");
        setamount("");
        setstatus("");
        setperson("");
        setfileName("");
        setemail("");
        setisEdit({ isEdit: false, editId: null });
        getInvoices();
        toast.success("Invoice Submitted");
        handleClose();
        handleClose2();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const [searchuser, setsearchuser] = useState("");
  const [fetchedusers, setfetchedusers] = useState([]);
  // const [selectedUser, setselectedUser] = useState(null);
  const fetchUserByName = async () => {
    try {
      const response = await axios.get(`/api/admin/searchuser?s=${searchuser}`);
      if (response.data.success) {
        setfetchedusers(response.data.data);
      }
    } catch (error) {
      if (error && error.response && error.response.data) {
        toast.error(error.response.data.error);
      }
    }
  };

  useEffect(() => {
    if (searchuser?.trim() != "") fetchUserByName();
    else setfetchedusers([]);
  }, [searchuser]);

  React.useEffect(() => {
    getInvoices();
  }, [period, startDate, endDate]);

  const handlechangeinvoiceTable = (e, id, name) => {
    const toBeupdated = [...uploadedInvoices];
    for (const t of toBeupdated) {
      if (t.id === id) {
        t[name] = e.target.value;
      }
    }
    setuploadedInvoices(toBeupdated);
  };
  const handleConnectUser = async (email, userId) => {
    if (currentuserid || isEdit.isEdit) {
      try {
        const response = await axios.get(`/api/sale/byuserid/${userId}`);
        if (response.data.success) {
          const toBeupdated = [...uploadedInvoices];
          for (const t of toBeupdated) {
            if (t.id === currentuserid) {
              t.email = email;
              t.userId = userId;
              if (response.data.data) {
                t.crmid = response.data.data;
              }
            }
          }
          if (isEdit.isEdit) {
            setemail(email);
            setuserid(userId);
            setcrmid(response.data.data);
          } else setuploadedInvoices(toBeupdated);
        }
      } catch (error) {
        if (error && error.response && error.response.data) {
          toast.error(error.response.data.error);
        }
      }
    }
  };

  const [currentuserid, setcurrentuserid] = useState(null);
  return (
    <div>
      <div className="d-flex align_center">
        <button className="gradient_admin_btn" onClick={() => handleOpen()}>
          Create Invoice <Add />
        </button>
        &nbsp; &nbsp;
        <button className="gradient_admin_btn" onClick={() => handleOpen2()}>
          Upload Offline Invoices
        </button>
        &nbsp; &nbsp;
        <h5>
          Total Revenue:{" "}
          <span style={{ color: "green", fontWeight: "bold" }}>
            EUR{" "}
            {totalRevenue.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </span>
        </h5>
        <div
          className="ms-auto"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <label>Period</label>
          <select
            className="auth_input"
            value={period}
            onChange={(e) => setperiod(e.target.value)}
          >
            <option value="all">All</option>
            <option>
              {monthNames[new Date().getMonth()]} {new Date().getFullYear()}
            </option>
            {previousMonths &&
              previousMonths
                .slice(-3)
                .reverse()
                .map((mon, i) => (
                  <option value={mon} key={i}>
                    {mon} {new Date().getFullYear()}
                  </option>
                ))}
            <option value="custom">Custom</option>
          </select>
        </div>
        {period === "custom" && (
          <div className="ms-4">
            <label>Custom Period</label>
            <div className="d-flex">
              <input
                type="date"
                className="auth_input "
                value={startDate}
                onChange={(e) => setstartDate(e.target.value)}
              />
              <input
                type="date"
                className="auth_input ms-2"
                value={endDate}
                min={startDate}
                onChange={(e) => setendDate(e.target.value)}
              />
            </div>
          </div>
        )}
      </div>
      <div className="crm_table">
        <table style={{ width: "2400px" }} className="mt-5 plan_table">
          <thead className="plan_thead">
            <tr className="plan_thead_tr plan_tr">
              <th className="plan_thead_tr plan_td sticky-col first-col">
                Name
              </th>
              <th className="plan_thead_tr plan_td">Status</th>
              <th className="plan_thead_tr plan_td">Company</th>
              <th className="plan_thead_tr plan_td">Amount(EUR)</th>
              <th className="plan_thead_tr plan_td">Contact Person</th>
              <th className="plan_thead_tr plan_td">Email</th>
              <th className="plan_thead_tr plan_td">Type</th>
              <th className="plan_thead_tr plan_td">Date</th>
              <th
                className="plan_thead_tr plan_td"
                style={{ width: "20px" }}
              ></th>
            </tr>
          </thead>
          <tbody className="plan_tbody">
            {allInvoices &&
              allInvoices.map((obj, i) => (
                <tr key={i} className="plan_tbody_tr plan_tr">
                  <td
                    className="plan_tbody_td plan_td sticky-col first-col"
                    style={{
                      backgroundColor: i % 2 === 0 ? "#fff" : "#f6f6f6",
                    }}
                  >
                    <a href={obj.invoice} target="_blank" rel="noreferrer">
                      {obj.fileName}
                    </a>
                  </td>
                  <td
                    className={`plan_tbody_td plan_td ${
                      obj.status === "paid"
                        ? "green"
                        : obj.status === "pending"
                        ? "blue"
                        : obj.status === "declined"
                        ? "red"
                        : ""
                    }`}
                  >
                    {obj.status}
                  </td>
                  <td className="plan_tbody_td plan_td">{obj.customer}</td>
                  <td className="plan_tbody_td plan_td">
                    {obj.amount.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </td>
                  <td className="plan_tbody_td plan_td">{obj.person}</td>
                  <td className="plan_tbody_td plan_td">{obj.email}</td>
                  <td className="plan_tbody_td plan_td">{obj.type}</td>
                  <td className="plan_tbody_td plan_td">{obj.date}</td>
                  <td
                    onClick={() => {
                      setcustomer(obj.customer);
                      setstatus(obj.status);
                      setType(obj.type);
                      setemail(obj.email);
                      setdate(moment(obj.date).format("YYYY-MM-DD"));
                      setamount(obj.amount);
                      setperson(obj.person);
                      setinvoiceUrl(obj.invoice);
                      setisEdit({ isEdit: true, editId: obj._id });
                      setfileName(obj.fileName);
                      handleOpen();
                    }}
                  >
                    <IconButton>
                      <DriveFileRenameOutline />
                    </IconButton>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <p className="bold">Upload Offline Invoice</p>
          <label htmlFor="photoid" className="investor-file py-2 mb-2">
            Choose file
            <input
              type="file"
              accept="application/pdf image/*"
              name="photoid"
              id="photoid"
              hidden
              multiple
              onChange={(e) => handleDocumentsChange(e)}
            />
          </label>
          &nbsp; &nbsp; &nbsp;
          {isUploading ? <CircularProgress /> : null}
          {invoiceUrl && invoiceUrl !== "" && (
            <a
              href={invoiceUrl}
              target="_blank"
              rel="noreferrer"
              className="black"
            >
              File
            </a>
          )}
          <div className="d-flex flex-wrap">
            <input
              type="text"
              placeholder="Customer"
              className="w-40 auth_input"
              value={customer}
              onChange={(e) => setcustomer(e.target.value)}
            />
            <input
              type="number"
              placeholder="Amount"
              className="w-40 auth_input ms-4"
              value={amount}
              onChange={(e) => setamount(e.target.value)}
            />
            <input
              type="date"
              placeholder="Date"
              className="w-30 auth_input"
              value={date}
              onChange={(e) => setdate(e.target.value)}
            />

            <select
              type="text"
              placeholder="Status"
              className="w-30 auth_input ms-4"
              value={status}
              onChange={(e) => setstatus(e.target.value)}
            >
              <option value={""}>Choose status</option>
              <option value={"paid"}>Paid</option>
              <option value={"pending"}>Pending</option>
              <option value={"declined"}>Declined</option>
            </select>
            <select
              type="text"
              placeholder="Type of Invoice"
              className="w-30 auth_input ms-4"
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <option value={""}>Choose Type of Invoice</option>
              <option value={"Platform"}>Platform</option>
              <option value={"Trendist"}>Trendist</option>
              <option value={"CI"}>CI</option>
              <option value={"Website"}>Website</option>
              <option value={"Tech Development"}>Tech Development</option>
              <option value={"Report"}>Report</option>
              <option value={"Others"}>Others</option>
            </select>
            <input
              type="text"
              placeholder="Contact Person"
              className="w-40 auth_input"
              value={person}
              onChange={(e) => setperson(e.target.value)}
            />
            <input
              type="text"
              placeholder="Email"
              className="w-40 auth_input  ms-4"
              value={email}
              onChange={(e) => setemail(e.target.value)}
            />
            <input
              type="text"
              placeholder="File Name"
              className="w-40 auth_input "
              value={fileName}
              onChange={(e) => setfileName(e.target.value)}
            />

            {/* <label
                // onClick={() => setcurrentuserid(obj.id)}
                className="w-100 white"
                htmlFor="name"
              >
                Search Connected User
              </label> */}
            <div className="w-40 ms-4 relative">
              <input
                value={searchuser}
                className="auth_input"
                onChange={(e) => {
                  // setcurrentuserid(obj.id);
                  setsearchuser(e.target.value);
                }}
                type="text"
                placeholder="Search connected user by firstname/company/email"
              />

              {searchuser.length > 0 &&
                fetchedusers &&
                fetchedusers.length > 0 && (
                  <div className="gd_explore_main_results_block sales_form_users gd_explore_main_results_block_inputs">
                    <div style={{ backgroundColor: "white" }}>
                      {fetchedusers.map((v, i) => (
                        <div
                          key={i}
                          onClick={() => {
                            handleConnectUser(v.email, v._id);
                            setfetchedusers([]);
                            setcurrentuserid(null);
                            setsearchuser("");
                          }}
                          className="gd_explore_main_result d-flex justify-content-between align-items-center mb-16 pointer"
                        >
                          <div className="d-flex align-items-center px-2 py-1">
                            <Avatar
                              src={v.avatar}
                              alt={v.name ?? v.firstname}
                              style={{ height: 40, width: 40 }}
                            />
                            <div style={{ color: "black" }} className="ms-3">
                              <p className="body1 mb-0">
                                {v.type === "member"
                                  ? `${v.firstname} ${v.lastname}, (${v.type}) `
                                  : `${v.name}, (${v.firstname} ${v.lastname}, ${v.type})`}

                                <span className="caption">{v.email}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
            </div>
          </div>
          <button
            className="gradient_admin_btn mt-3"
            onClick={() => saveInvoice()}
          >
            {isEdit.isEdit ? "Edit Invoice" : "Add Invoice"}
          </button>
        </Box>
      </Modal>
      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <p className="bold">Upload Offline Invoice</p>
          <label htmlFor="photoid" className="investor-file py-2 mb-2">
            Choose file
            <input
              type="file"
              accept="application/pdf image/*"
              name="photoid"
              id="photoid"
              hidden
              multiple
              onChange={(e) => handleDocumentsChange(e)}
            />
          </label>
          &nbsp; &nbsp; &nbsp;
          {isUploading ? <CircularProgress /> : null}
          <table style={{ width: "100%" }} className="mt-5 plan_table">
            <thead className="plan_thead">
              <tr className="plan_thead_tr plan_tr">
                <th className="plan_thead_tr plan_td">Name</th>
                <th className="plan_thead_tr plan_td">Status</th>
                <th className="plan_thead_tr plan_td">Company</th>
                <th className="plan_thead_tr plan_td">Amount(EUR)</th>
                <th className="plan_thead_tr plan_td">Contact Person</th>
                <th className="plan_thead_tr plan_td">Date</th>
                <th className="plan_thead_tr plan_td">Connected user</th>
                <th className="plan_thead_tr plan_td">Email</th>
                <th className="plan_thead_tr plan_td">ID</th>
                <th
                  className="plan_thead_tr plan_td"
                  style={{ width: "20px" }}
                ></th>
              </tr>
            </thead>
            <tbody className="plan_tbody">
              {uploadedInvoices &&
                uploadedInvoices.map((obj, i) => (
                  <tr key={i} className="plan_tbody_tr plan_tr">
                    <td className="plan_tbody_td plan_td">
                      <a href={obj.invoice} target="_blank" rel="noreferrer">
                        {obj.fileName}
                      </a>
                    </td>
                    <td className="plan_tbody_td plan_td">
                      <select
                        type="text"
                        placeholder="Status"
                        // className="w-40 auth_input ms-4"
                        value={obj.status}
                        onChange={(e) =>
                          handlechangeinvoiceTable(e, obj.id, "status")
                        }
                        // setstatus={(e) => setstatus(e.target.value)}
                      >
                        <option value={""}>Choose status</option>
                        <option value={"paid"}>Paid</option>
                        <option value={"pending"}>Pending</option>
                        <option value={"declined"}>Declined</option>
                      </select>
                    </td>
                    <td className="plan_tbody_td plan_td">
                      <input
                        type="text"
                        className="auth_input "
                        value={obj.customer}
                        onChange={(e) =>
                          handlechangeinvoiceTable(e, obj.id, "customer")
                        }
                      />
                    </td>
                    <td className="plan_tbody_td plan_td">
                      <input
                        type="number"
                        className="auth_input "
                        value={obj.amount}
                        onChange={(e) =>
                          handlechangeinvoiceTable(e, obj.id, "amount")
                        }
                      />
                    </td>
                    <td className="plan_tbody_td plan_td">
                      <input
                        type="text"
                        className="auth_input "
                        value={obj.person}
                        onChange={(e) =>
                          handlechangeinvoiceTable(e, obj.id, "person")
                        }
                      />
                    </td>
                    <td className="plan_tbody_td plan_td">
                      <input
                        type="date"
                        className="auth_input "
                        value={obj.date}
                        onChange={(e) =>
                          handlechangeinvoiceTable(e, obj.id, "date")
                        }
                      />
                    </td>
                    <td className="plan_tbody_td plan_td">
                      <div className="col-md-3 col-lg-3 col-sm-12 relative">
                        <label
                          onClick={() => setcurrentuserid(obj.id)}
                          // className="w-100 white"
                          htmlFor="name"
                        >
                          Search Connected User
                        </label>
                        {obj.id === currentuserid && (
                          <input
                            value={searchuser}
                            // className="w-100 mb-3 input-tag"
                            onChange={(e) => {
                              setcurrentuserid(obj.id);
                              setsearchuser(e.target.value);
                            }}
                            type="text"
                            placeholder="Search by firstname/company/email"
                          />
                        )}

                        {searchuser.length > 0 &&
                          fetchedusers &&
                          obj.id === currentuserid &&
                          fetchedusers.length > 0 && (
                            <div className="gd_explore_main_results_block sales_form_users">
                              <div style={{ backgroundColor: "white" }}>
                                {fetchedusers.map((v, i) => (
                                  <div
                                    key={i}
                                    onClick={() => {
                                      handleConnectUser(v.email, v._id);
                                      setfetchedusers([]);
                                      setcurrentuserid(null);
                                      setsearchuser("");
                                    }}
                                    className="gd_explore_main_result d-flex justify-content-between align-items-center mb-16 pointer"
                                  >
                                    <div className="d-flex align-items-center px-2 py-1">
                                      <Avatar
                                        src={v.avatar}
                                        alt={v.name ?? v.firstname}
                                        style={{ height: 40, width: 40 }}
                                      />
                                      <div
                                        style={{ color: "black" }}
                                        className="ms-3"
                                      >
                                        <p className="body1 mb-0">
                                          {v.type === "member"
                                            ? `${v.firstname} ${v.lastname}, (${v.type}) `
                                            : `${v.name}, (${v.firstname} ${v.lastname}, ${v.type})`}

                                          <span className="caption">
                                            {v.email}
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                      </div>
                    </td>
                    <td className="plan_tbody_td plan_td">
                      <input
                        type="text"
                        className="auth_input "
                        value={obj.email}
                        onChange={(e) =>
                          handlechangeinvoiceTable(e, obj.id, "email")
                        }
                      />
                    </td>
                    <td className="plan_tbody_td plan_td">
                      <input
                        type="text"
                        className="auth_input "
                        value={obj.userId}
                        onChange={(e) =>
                          handlechangeinvoiceTable(e, obj.id, "userId")
                        }
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <button
            hidden={uploadedInvoices.length === 0}
            className="gradient_admin_btn mt-3"
            onClick={() => saveInvoice()}
          >
            Upload Invoices
          </button>
        </Box>
      </Modal>
    </div>
  );
}
